/**
 * This file was automatically generated by @cosmwasm/ts-codegen@1.10.0.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */

import { UseQueryOptions } from '@tanstack/react-query'

import {
  ActiveThresholdResponse,
  AnyContractInfo,
  Auth,
  Boolean,
  InfoResponse,
  TotalPowerAtHeightResponse,
  VotingPowerAtHeightResponse,
} from '@dao-dao/types/contracts/SecretDaoVotingSnip20Staked'
import { getCosmWasmClientForChainId } from '@dao-dao/utils'

import { SecretDaoVotingSnip20StakedQueryClient } from '../../../contracts/SecretDaoVotingSnip20Staked'

export const secretDaoVotingSnip20StakedQueryKeys = {
  contract: [
    {
      contract: 'secretDaoVotingSnip20Staked',
    },
  ] as const,
  address: (chainId: string, contractAddress: string) =>
    [
      {
        ...secretDaoVotingSnip20StakedQueryKeys.contract[0],
        chainId,
        address: contractAddress,
      },
    ] as const,
  stakingContract: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretDaoVotingSnip20StakedQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'staking_contract',
        args,
      },
    ] as const,
  activeThreshold: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretDaoVotingSnip20StakedQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'active_threshold',
        args,
      },
    ] as const,
  votingPowerAtHeight: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretDaoVotingSnip20StakedQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'voting_power_at_height',
        args,
      },
    ] as const,
  totalPowerAtHeight: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretDaoVotingSnip20StakedQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'total_power_at_height',
        args,
      },
    ] as const,
  dao: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretDaoVotingSnip20StakedQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'dao',
        args,
      },
    ] as const,
  info: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretDaoVotingSnip20StakedQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'info',
        args,
      },
    ] as const,
  tokenContract: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretDaoVotingSnip20StakedQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'token_contract',
        args,
      },
    ] as const,
  isActive: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...secretDaoVotingSnip20StakedQueryKeys.address(
          chainId,
          contractAddress
        )[0],
        method: 'is_active',
        args,
      },
    ] as const,
}
export const secretDaoVotingSnip20StakedQueries = {
  stakingContract: <TData = AnyContractInfo>({
    chainId,
    contractAddress,
    options,
  }: SecretDaoVotingSnip20StakedStakingContractQuery<TData>): UseQueryOptions<
    AnyContractInfo,
    Error,
    TData
  > => ({
    queryKey: secretDaoVotingSnip20StakedQueryKeys.stakingContract(
      chainId,
      contractAddress
    ),
    queryFn: async () =>
      new SecretDaoVotingSnip20StakedQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).stakingContract(),
    ...options,
  }),
  activeThreshold: <TData = ActiveThresholdResponse>({
    chainId,
    contractAddress,
    options,
  }: SecretDaoVotingSnip20StakedActiveThresholdQuery<TData>): UseQueryOptions<
    ActiveThresholdResponse,
    Error,
    TData
  > => ({
    queryKey: secretDaoVotingSnip20StakedQueryKeys.activeThreshold(
      chainId,
      contractAddress
    ),
    queryFn: async () =>
      new SecretDaoVotingSnip20StakedQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).activeThreshold(),
    ...options,
  }),
  votingPowerAtHeight: <TData = VotingPowerAtHeightResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: SecretDaoVotingSnip20StakedVotingPowerAtHeightQuery<TData>): UseQueryOptions<
    VotingPowerAtHeightResponse,
    Error,
    TData
  > => ({
    queryKey: secretDaoVotingSnip20StakedQueryKeys.votingPowerAtHeight(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () =>
      new SecretDaoVotingSnip20StakedQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).votingPowerAtHeight({
        auth: args.auth,
        height: args.height,
      }),
    ...options,
  }),
  totalPowerAtHeight: <TData = TotalPowerAtHeightResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: SecretDaoVotingSnip20StakedTotalPowerAtHeightQuery<TData>): UseQueryOptions<
    TotalPowerAtHeightResponse,
    Error,
    TData
  > => ({
    queryKey: secretDaoVotingSnip20StakedQueryKeys.totalPowerAtHeight(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () =>
      new SecretDaoVotingSnip20StakedQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).totalPowerAtHeight({
        height: args.height,
      }),
    ...options,
  }),
  dao: <TData = AnyContractInfo>({
    chainId,
    contractAddress,
    options,
  }: SecretDaoVotingSnip20StakedDaoQuery<TData>): UseQueryOptions<
    AnyContractInfo,
    Error,
    TData
  > => ({
    queryKey: secretDaoVotingSnip20StakedQueryKeys.dao(
      chainId,
      contractAddress
    ),
    queryFn: async () =>
      new SecretDaoVotingSnip20StakedQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).dao(),
    ...options,
  }),
  info: <TData = InfoResponse>({
    chainId,
    contractAddress,
    options,
  }: SecretDaoVotingSnip20StakedInfoQuery<TData>): UseQueryOptions<
    InfoResponse,
    Error,
    TData
  > => ({
    queryKey: secretDaoVotingSnip20StakedQueryKeys.info(
      chainId,
      contractAddress
    ),
    queryFn: async () =>
      new SecretDaoVotingSnip20StakedQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).info(),
    ...options,
  }),
  tokenContract: <TData = AnyContractInfo>({
    chainId,
    contractAddress,
    options,
  }: SecretDaoVotingSnip20StakedTokenContractQuery<TData>): UseQueryOptions<
    AnyContractInfo,
    Error,
    TData
  > => ({
    queryKey: secretDaoVotingSnip20StakedQueryKeys.tokenContract(
      chainId,
      contractAddress
    ),
    queryFn: async () =>
      new SecretDaoVotingSnip20StakedQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).tokenContract(),
    ...options,
  }),
  isActive: <TData = Boolean>({
    chainId,
    contractAddress,
    options,
  }: SecretDaoVotingSnip20StakedIsActiveQuery<TData>): UseQueryOptions<
    Boolean,
    Error,
    TData
  > => ({
    queryKey: secretDaoVotingSnip20StakedQueryKeys.isActive(
      chainId,
      contractAddress
    ),
    queryFn: async () =>
      new SecretDaoVotingSnip20StakedQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).isActive(),
    ...options,
  }),
}
export interface SecretDaoVotingSnip20StakedReactQuery<
  TResponse,
  TData = TResponse,
> {
  chainId: string
  contractAddress: string
  options?: Omit<
    UseQueryOptions<TResponse, Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: undefined
  }
}
export interface SecretDaoVotingSnip20StakedIsActiveQuery<TData>
  extends SecretDaoVotingSnip20StakedReactQuery<Boolean, TData> {}
export interface SecretDaoVotingSnip20StakedTokenContractQuery<TData>
  extends SecretDaoVotingSnip20StakedReactQuery<AnyContractInfo, TData> {}
export interface SecretDaoVotingSnip20StakedInfoQuery<TData>
  extends SecretDaoVotingSnip20StakedReactQuery<InfoResponse, TData> {}
export interface SecretDaoVotingSnip20StakedDaoQuery<TData>
  extends SecretDaoVotingSnip20StakedReactQuery<AnyContractInfo, TData> {}
export interface SecretDaoVotingSnip20StakedTotalPowerAtHeightQuery<TData>
  extends SecretDaoVotingSnip20StakedReactQuery<
    TotalPowerAtHeightResponse,
    TData
  > {
  args: {
    height?: number
  }
}
export interface SecretDaoVotingSnip20StakedVotingPowerAtHeightQuery<TData>
  extends SecretDaoVotingSnip20StakedReactQuery<
    VotingPowerAtHeightResponse,
    TData
  > {
  args: {
    auth: Auth
    height?: number
  }
}
export interface SecretDaoVotingSnip20StakedActiveThresholdQuery<TData>
  extends SecretDaoVotingSnip20StakedReactQuery<
    ActiveThresholdResponse,
    TData
  > {}
export interface SecretDaoVotingSnip20StakedStakingContractQuery<TData>
  extends SecretDaoVotingSnip20StakedReactQuery<AnyContractInfo, TData> {}
